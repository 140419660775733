// appUrl.js

// Function to determine the base URL dynamically
const getBaseUrl = () => {
    const { hostname } = window.location;

    if (hostname === 'localhost') {
        // Local development environment
        return 'http://localhost:8000';
    } else if (hostname === 'devadmin.factorflow.com') {
        // Development environment
        return 'https://devadminbe.factorflow.com';
    } else if (hostname === 'stgadmin.factorflow.com') {
        // Staging environment
        return 'https://stgadminbe.factorflow.com';
    } else if (hostname === 'admin.factorflow.com') {
        // Production environment
        return 'https://adminbe.factorflow.com';
    }
};

// Set the base URL dynamically
const baseUrl = getBaseUrl();

const appURL = {
    baseUrl: baseUrl + '/api/v1/',

    // Login
    adminLogin: "auth/login",
    fetchUserDetails: "auth/me",
    refreshToken: "auth/refresh",
    adminLogout: "auth/logout",

    // Company
    getCompanyList: "company/paginate",
    companyFetch: "company/fetch",
    companyAdd: "company/save",
    fetchcompanyDetails: "company/details",
    companyEdit: "company/save",
    companyDelete: "company/delete",
    changeCompanyStatus: "company/change_status",
    companyUserUpdate: "company/update_user",
    invitePOC: "company/invite_poc",

    // User
    getCompanyUserList: "user/paginate",
    getCompanyUserFetch: "user/fetch",
    changeCompanyUserStatus: "user/change_status",
    inviteUser: "user/invite",
    resendInvite: "user/resend_invite",

    // Product Rights
    getProductRightList: "right/paginate",
    addProductRight: "right/save",
    updateProductRight: "right/save",
    deleteProductRight: "right/delete",
    getModuleOrder: "right/get_module_order",
    getSubModuleOrder: "right/get_sub_module_order",

    // Roles
    fetchRolesList: "role/fetch_list",

    // global APIs
    getZipCodesList: "zip_codes/fetch",
    getZipCode: "zip_codes/fetch_code",
};

export default appURL;
