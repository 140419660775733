import appURL from "../../constants/appURL";
import axiosInstance from "../../service/axios_apis";
const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");
const productRightMainList = createSlice({
    name: "productRight",
    initialState: {},
    reducers: {
        resetProductRightSlice(state, action) {
            return { ...state, addSuccess: undefined, updateSuccess: undefined, deleteSuccess: undefined, moduleOrderLoading: undefined, moduleOrderData: undefined, subModuleOrderLoading: undefined, subModuleOrderData: undefined };
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getProductRightList.pending, (state, action) => {
                return { ...state, loading: true };
            })
            .addCase(getProductRightList.fulfilled, (state, action) => {
                return { ...state, loading: false, data: action.payload };
            })
            .addCase(getProductRightList.rejected, (state, action) => {
                return {
                    ...state,
                    loading: false,
                    error: action.payload,
                };
            });
        builder
            .addCase(addProductRight.pending, (state, action) => {
                return { ...state, addLoading: true };
            })
            .addCase(addProductRight.fulfilled, (state, action) => {
                return { ...state, addLoading: false, addData: action.payload, addSuccess: true };
            })
            .addCase(addProductRight.rejected, (state, action) => {
                return {
                    ...state,
                    addLoading: false,
                    addError: action.payload,
                    addSuccess: false,
                };
            });
        builder
            .addCase(updateProductRight.pending, (state, action) => {
                return { ...state, updateLoading: true };
            })
            .addCase(updateProductRight.fulfilled, (state, action) => {
                return { ...state, updateLoading: false, updateData: action.payload, updateSuccess: true };
            })
            .addCase(updateProductRight.rejected, (state, action) => {
                return {
                    ...state,
                    updateLoading: false,
                    updateError: action.payload,
                    updateSuccess: false,
                };
            });
        builder
            .addCase(deleteProductRight.pending, (state, action) => {
                return { ...state, deleteLoading: true };
            })
            .addCase(deleteProductRight.fulfilled, (state, action) => {
                return { ...state, deleteLoading: false, deleteData: action.payload, deleteSuccess: true };
            })
            .addCase(deleteProductRight.rejected, (state, action) => {
                return {
                    ...state,
                    deleteLoading: false,
                    deleteError: action.payload,
                    deleteSuccess: false,
                };
            });

        builder
            .addCase(getModuleOrder.pending, (state, action) => {
                return { ...state, moduleOrderLoading: true };
            })
            .addCase(getModuleOrder.fulfilled, (state, action) => {
                return { ...state, moduleOrderLoading: false, moduleOrderData: action.payload };
            })
            .addCase(getModuleOrder.rejected, (state, action) => {
                return {
                    ...state,
                    moduleOrderLoading: false,
                    moduleOrderError: action.payload,
                };
            });

        builder
            .addCase(getSubModuleOrder.pending, (state, action) => {
                return { ...state, subModuleOrderLoading: true };
            })
            .addCase(getSubModuleOrder.fulfilled, (state, action) => {
                return { ...state, subModuleOrderLoading: false, subModuleOrderData: action.payload };
            })
            .addCase(getSubModuleOrder.rejected, (state, action) => {
                return {
                    ...state,
                    subModuleOrderLoading: false,
                    subModuleOrderError: action.payload,
                };
            });
    },
});
export default productRightMainList.reducer;
export const { resetProductRightSlice } = productRightMainList.actions;
// Thunks
export const getProductRightList = createAsyncThunk("right/fetch", async (id, { rejectWithValue, fulfillWithValue }) => {
    try {
        const { data } = await axiosInstance.post(appURL.baseUrl + appURL.getProductRightList, id, { withCredentials: true });
        return fulfillWithValue(data?.rights);
    } catch (error) {
        throw rejectWithValue(error.response && error.response.data.Message ? error.response.data.Message : error.message);
    }
});
export const addProductRight = createAsyncThunk("right/add", async (body, { rejectWithValue, fulfillWithValue }) => {
    try {
        const { data } = await axiosInstance.post(appURL.baseUrl + appURL.addProductRight, body, { withCredentials: true });
        return fulfillWithValue(data.right);
    } catch (error) {
        throw rejectWithValue(error.response && error.response.data.Message ? error.response.data.Message : error.message);
    }
});
export const updateProductRight = createAsyncThunk("right/patch", async (body, { rejectWithValue, fulfillWithValue }) => {
    try {
        const { data } = await axiosInstance.post(appURL.baseUrl + appURL.updateProductRight, body, { withCredentials: true });
        return fulfillWithValue(data.data);
    } catch (error) {
        throw rejectWithValue(error.response && error.response.data.Message ? error.response.data.Message : error.message);
    }
});
export const deleteProductRight = createAsyncThunk("right/delete", async (deleteId, { rejectWithValue, fulfillWithValue }) => {
    try {
        const { data } = await axiosInstance.post(appURL.baseUrl + appURL.deleteProductRight, deleteId, { withCredentials: true });
        return fulfillWithValue(data.data);
    } catch (error) {
        throw rejectWithValue(error.response && error.response.data.Message ? error.response.data.Message : error.message);
    }
});
export const getModuleOrder = createAsyncThunk("right/get_module_order", async (body, { rejectWithValue, fulfillWithValue }) => {
    try {
        const { data } = await axiosInstance.post(appURL.baseUrl + appURL.getModuleOrder, body, { withCredentials: true });
        return fulfillWithValue(data.order);
    } catch (error) {
        throw rejectWithValue(error.response && error.response.data.Message ? error.response.data.Message : error.message);
    }
});
export const getSubModuleOrder = createAsyncThunk("right/get_sub_module_order", async (body, { rejectWithValue, fulfillWithValue }) => {
    try {
        const { data } = await axiosInstance.post(appURL.baseUrl + appURL.getSubModuleOrder, body, { withCredentials: true });
        return fulfillWithValue(data.order);
    } catch (error) {
        throw rejectWithValue(error.response && error.response.data.Message ? error.response.data.Message : error.message);
    }
});
