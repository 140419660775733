import React from "react";
//styles
import styles from "./globaldropdown.module.css";

import { Dropdown } from "primereact/dropdown";
const GlobalDropdown = (props) => {
    const { value, options, onChange, optionLabel, placeholder, name, id, editable = false, label, isRequired, isDependent, onClick, optionValue } = props;
    return (
        <>
            {label && (
                <div className="d-flex mb-2">
                    <label>
                        <b>{label}</b>
                        {(isRequired || isDependent) && <span className={`${isDependent ? "clr_green" : "clr_red"}  `}>*</span>}
                    </label>
                </div>
            )}
            <Dropdown value={value} options={options} onClick={onClick} onChange={onChange} optionLabel={optionLabel} placeholder={placeholder} className={styles.filterbar_dropdown} name={name} id={id} editable={editable} optionValue={optionValue} />
        </>
    );
};

export default GlobalDropdown;
