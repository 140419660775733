import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { InputSwitch } from "primereact/inputswitch";
import React, { useEffect, useState } from "react";
import { FaRegEdit } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import EditUser from "../../pages/productRight/components";
import { changeCompanyUserStatus, getCompanyUserList, getZipCodesList, resetCompanyUserSlice, updateCompany } from "../../redux/auth_slice/company_user_slice";
import DefaultButton from "../../ui-components/defaultbutton/defaultbutton";
import GlobalDialogIndex from "../../ui-components/globaldialoge";
import GlobalDropdown from "../../ui-components/globaldropdown";
import GlobalInputField from "../../ui-components/globalinputfield";
import GlobalTextarea from "../../ui-components/globaltextarea";
import PrimaryButton from "../../ui-components/primarybutton/primarybutton";
import SecondaryButton from "../../ui-components/secondarybutton/secondarybutton";
import * as Yup from "yup";
import CustomLoading from "../../ui-components/customSpinner/custom_spinner";
import { formateDateTime } from "../../service/date_service";
import { PaginatorTemplate } from "../../ui-components/paginatorTemplate/PaginatorTemplate";
import { BsSearch } from "react-icons/bs";
import InviteDialog from "../dashboardComponent/InviteDialog";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
dayjs.extend(utc);

const EditCompany = (props) => {
    const { onHide, activeTab, setActiveTab, editData } = props;

    const dispatch = useDispatch();
    const updateCompanyReducer = useSelector((state) => state.companyUser);
    const { updateSuccess, updateError, updateLoading } = updateCompanyReducer;

    const companyUserListReducer = useSelector((state) => state.companyUser);
    const { companyUserListData, companyUserListLoading } = companyUserListReducer;

    const companyUserStatusReducer = useSelector((state) => state.companyUser);
    const { companyUserStatusData, companyUserStatusError } = companyUserStatusReducer;

    const zipCodeReducer = useSelector((state) => state.companyUser);
    const { zipCodeData, zipCodeError } = zipCodeReducer;

    const [editUserData, setEditUserData] = useState();
    const [companyId, setCompanyId] = useState(editData?.id);

    useEffect(() => {
        if (companyUserListData?.data) {
            setPagination({
                ...pagination,
                totalRecords: companyUserListData?.total,
                totalPages: companyUserListData?.last_page,
                isLastPage: companyUserListData?.last_page,
            });
        }
    }, [companyUserListData]);

    // Search
    const [keyword, setKeyword] = useState(null);
    const [inviteDialog, setInviteDialog] = useState(false);

    // Pagination
    const [pageInputTooltip, setPageInputTooltip] = useState("Press 'Enter' key to go to this page.");
    const [currentPage, setCurrentPage] = useState(1);
    const [page, setPage] = useState(currentPage);
    const [pageSize, setPageSize] = useState(10);
    const [pagination, setPagination] = useState({
        totalRecords: 0,
        isFirstPage: 1,
        isLastPage: 0,
        totalPages: 0,
    });

    const [sortField, setSortField] = useState(null);
    const [sortOrder, setSortOrder] = useState(null);

    const [sortCount, setSortCount] = useState(0);
    const [showAll, setShowAll] = useState(0);

    // Pagination
    const onCustomPage = (event) => {
        setPageSize(event.rows);
        setCurrentPage(event.page + 1);
    };

    const payload = {
        page: page ? page : currentPage,
        per_page: pageSize,
        search_text: keyword,
        show_all: false,
        company_id: companyId,
    };

    if (sortField) {
        payload["sort_by"] = sortField;
    }
    if (sortOrder) {
        payload["sort_desc"] = sortOrder === 1 ? "asc" : "desc";
    }

    // Grid Sorting
    const onSort = (e) => {
        if (e.sortField === sortField) {
            if (sortCount === 1) {
                setSortCount(0);
                setSortField(null);
                setSortOrder(null);
            } else {
                setSortCount(sortCount + 1);
                setSortField(e.sortField);
                setSortOrder(e.sortOrder);
            }
        } else {
            setSortField(e.sortField);
            setSortOrder(e.sortOrder);
        }
    };

    useEffect(() => {
        if (keyword != null) {
            const delayDebounceFn = setTimeout(() => {
                if (currentPage != 1) {
                    setCurrentPage(1);
                    setPage(1);
                } else {
                    if (activeTab === "companyUser") {
                        dispatch(resetCompanyUserSlice());
                        dispatch(getCompanyUserList(payload));
                    }
                }
            }, 250);

            return () => clearTimeout(delayDebounceFn);
        }
    }, [keyword]);

    useEffect(() => {
        if (activeTab === "companyUser") {
            setKeyword("");
            dispatch(resetCompanyUserSlice());
            dispatch(getCompanyUserList(payload));
        }
    }, [showAll, sortField, sortOrder, pageSize, currentPage, updateSuccess, activeTab]);

    // State for managing form values
    const [formValues, setFormValues] = useState({
        companyName: editData?.name || "",
        companyPhoneNo: editData?.company_phone || "",
        addressLine1: editData?.address_line_1 || "",
        addressLine2: editData?.address_line_2 || "",
        zipCode: editData?.zip_code || "",
        state: editData?.state || "",
        city: editData?.city || "",
        authenticationType: editData?.authentication_type || "",
        pocFirstName: editData?.poc_first_name || "",
        pocLastName: editData?.poc_last_name || "",
        pocEmail: editData?.poc_email || "",
        pocPhoneNo: editData?.poc_phone_number || "",
        description: editData?.description || "",
    });

    useEffect(() => {
        if (formValues?.zipCode?.length === 5) {
            dispatch(getZipCodesList({ zip: formValues.zipCode }));
        } else {
            setFormValues((prevState) => ({
                ...prevState,
                city: "",
                state: "",
            }));
        }
    }, [formValues?.zipCode]);

    useEffect(() => {
        if (zipCodeData) {
            setFormValues((prevState) => ({
                ...prevState,
                city: zipCodeData.city,
                state: zipCodeData.full_state,
            }));
        }
    }, [zipCodeData]);

    useEffect(() => {
        if (zipCodeError && zipCodeError !== undefined) {
            toast.error(zipCodeError);
        }

        return () => {
            dispatch(resetCompanyUserSlice());
        };
    }, [zipCodeError]);

    // Handler for input changes
    const handleInputChange = (name, value) => {
        setFormValues((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };
    // States

    const [isEdit, setIsEdit] = useState(false);
    const [errors, setErrors] = useState({});

    // Dropdown
    const authenticationTypeOptions = [
        { label: "None", value: "0" },
        { label: "Authenticator", value: "1" },
        { label: "OTP", value: "2" },
    ];

    // Custom body template

    const editTemplate = (rowData) => {
        return (
            <div className="flex ">
                <FaRegEdit
                    onClick={() => {
                        setIsEdit(true);
                        setEditUserData("Edit"); // Set as "Edit"
                        setEditUserData(rowData); // Also set rowData
                    }}
                    style={{ fontSize: "20px", cursor: "pointer" }}
                />
            </div>
        );
    };
    // Tab Switch Button
    const handleTabChange = (tabName) => {
        setActiveTab(tabName);
    };

    const validationSchema = Yup.object({
        companyName: Yup.string().required("Company Name is required").max(50, "Max 50 characters"),
        companyPhoneNo: Yup.string()
            .required("Company Phone No is required")
            .test("phone-format", "Phone number must be in the format 123-456-7890", (value) => {
                if (!value) return true; // Don't validate format if the value is empty (as required check will handle it)
                return /^[0-9]{3}-[0-9]{3}-[0-9]{4}$/.test(value); // Format validation
            }),
        addressLine1: Yup.string().required("Address Line 1 is required").max(100, "Max 100 characters"),
        addressLine2: Yup.string().max(100, "Max 100 characters"),
        zipCode: Yup.string()
            .required("Zip Code is required")
            .test("length", "Zip Code must be 5 digits", (value) => !value || value.length === 5),
        state: Yup.string().required("State is required").max(50, "Max 50 characters"),
        city: Yup.string().required("City is required").max(50, "Max 50 characters"),
        authenticationType: Yup.string().required("Authentication Type is required"),
        pocFirstName: Yup.string().required("POC First Name is required").max(50, "Max 50 characters"),
        pocLastName: Yup.string().required("POC Last Name is required").max(50, "Max 50 characters"),
        pocEmail: Yup.string().required("POC Email is required").email("Invalid email"),
        pocPhoneNo: Yup.string()
            .required("POC Phone No is required")
            .test("phone-format", "Phone number must be in the format 123-456-7890", (value) => {
                if (!value) return true; // Don't validate format if the value is empty (as required check will handle it)
                return /^[0-9]{3}-[0-9]{3}-[0-9]{4}$/.test(value); // Format validation
            }),
    });

    const validateForm = async (formValues) => {
        try {
            await validationSchema.validate(formValues, { abortEarly: false });
            setErrors({});
            return {};
        } catch (err) {
            const errors = {};
            err.inner.forEach((error) => {
                errors[error.path] = error.message;
            });
            setErrors(errors);
            return errors;
        }
    };
    const handleUpdate = async () => {
        const errors = await validateForm(formValues);

        // Check if there are any validation errors
        if (Object.keys(errors).length > 0) {
            // Show errors (you can use a toast or display them inline in the form)
            console.log(errors); // For debugging, replace with appropriate error handling
            return;
        }

        const payload = {
            id: editData?.id || 0, // assuming the id is part of the original data
            name: formValues.companyName,
            phone_number: formValues.companyPhoneNo,
            address_line_1: formValues.addressLine1,
            address_line_2: formValues.addressLine2,
            zip_code: formValues.zipCode,
            state: formValues.state,
            city: formValues.city,
            authentication_type: formValues.authenticationType,
            poc_first_name: formValues.pocFirstName,
            poc_last_name: formValues.pocLastName,
            poc_email: formValues.pocEmail,
            poc_phone_number: formValues.pocPhoneNo,
            description: formValues.description,
        };

        payload.id = editData.id;
        dispatch(updateCompany(payload));
    };

    useEffect(() => {
        if (updateSuccess !== undefined) {
            if (updateSuccess === true) {
                toast.success("Company updated successfully!");
                onHide();
            } else {
                toast.error(updateError);
            }
        }

        return () => {
            dispatch(resetCompanyUserSlice());
        };
    }, [updateSuccess, updateError]);

    useEffect(() => {
        const validate = async () => {
            const validationErrors = await validateForm(formValues);
            setErrors(validationErrors);
        };

        validate();
    }, [formValues]);

    const handleStatusChange = async (rowData, newValue) => {
        try {
            await dispatch(
                changeCompanyUserStatus({
                    id: rowData.id,
                    status: newValue === true ? 1 : 0,
                })
            ).unwrap();
            dispatch(getCompanyUserList({ company_id: companyId }));
        } catch (error) {
            console.error("Error updating status:", error);
        }
    };

    useEffect(() => {
        if (companyUserStatusData !== undefined) {
            toast.success("Company user status updated successfully!");
        }

        if (companyUserStatusError !== undefined) {
            toast.success("Something went wrong, please try again!");
        }

        return () => {
            dispatch(resetCompanyUserSlice());
        };
    }, [companyUserStatusData, companyUserStatusError]);

    useEffect(() => {
        if (formValues?.authenticationType === "None") {
            setFormValues((prevState) => ({
                ...prevState,
                authenticationType: "0",
            }));
        } else if (formValues?.authenticationType === "Authenticator") {
            setFormValues((prevState) => ({
                ...prevState,
                authenticationType: "1",
            }));
        } else if (formValues?.authenticationType === "OTP") {
            setFormValues((prevState) => ({
                ...prevState,
                authenticationType: "2",
            }));
        }
    }, [formValues?.authenticationType]);

    const statusTemplate = (rowData) => {
        return (
            <InputSwitch
                checked={rowData.is_active}
                onChange={(e) => {
                    const newValue = e.value;
                    handleStatusChange(rowData, newValue);
                }}
            />
        );
    };

    return (
        <>
            <div className="grid">
                <div className="col-12 md:col-2">
                    <DefaultButton className={`mb-2 ${activeTab === "companySummary" ? "active-button" : ""}`} type="button" name="companySummary" id="companySummary" label="Company Summary" onClick={() => handleTabChange("companySummary")} />
                    <DefaultButton className={`mb-2 ${activeTab === "companyUser" ? "active-button" : ""}`} type="button" name="companyUser" id="companyUser" label="Company Users" onClick={() => handleTabChange("companyUser")} />
                </div>

                <div className="col-12 md:col-10">
                    {/* Company Summary */}
                    {activeTab === "companySummary" && (
                        <>
                            <div className="grid custom_scroll">
                                {editData.is_active === true && editData.onboarded !== true && (
                                    <>
                                        <div className="md:col-10"></div>
                                        <div className="col-12 md:col-2">
                                            <PrimaryButton label={editData.invitation_date !== null ? "Reinvite POC" : "Invite POC"} onClick={() => setInviteDialog(true)} style={{ width: "100%" }} disabled={editData.invitation_date && dayjs().utc().diff(dayjs.utc(editData.invitation_date), "day") < 1} />
                                        </div>
                                    </>
                                )}

                                <div className="col-12 md:col-4">
                                    <GlobalInputField type="text" name="companyName" id="companyName" label="Company Name" placeholder="Enter Company Name" value={formValues.companyName} isRequired={true} onChange={(e) => handleInputChange("companyName", e.target.value)} maxLength={50} />
                                    {errors.companyName && <small className="p-error">{errors.companyName}</small>}
                                </div>
                                <div className="col-12 md:col-4">
                                    <GlobalInputField
                                        type="text"
                                        name="companyPhoneNo"
                                        id="companyPhoneNo"
                                        label="Company Phone No"
                                        placeholder="Enter Company Phone No"
                                        value={formValues.companyPhoneNo}
                                        isRequired={true}
                                        onChange={(e) => {
                                            // Update only if the value contains numbers
                                            const numericValue = e.target.value.replace(/[^0-9]/g, "");
                                            const formattedValue = numericValue
                                                .replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3") // Format as 123-456-7898
                                                .substr(0, 12); // Limit length
                                            setFormValues((prevValues) => ({
                                                ...prevValues, // Preserve the rest of the form values
                                                companyPhoneNo: formattedValue, // Update only zipCode
                                            }));
                                        }}
                                        maxLength={12}
                                    />
                                    {errors.companyPhoneNo && <small className="p-error">{errors.companyPhoneNo}</small>}
                                </div>
                                <div className="col-12 md:col-4">
                                    <GlobalInputField type="text" name="addressLine1" id="addressLine1" label="Address Line 1" placeholder="Enter Address Line 1" value={formValues.addressLine1} onChange={(e) => handleInputChange("addressLine1", e.target.value)} isRequired={true} maxLength={100} />
                                    {errors.addressLine1 && <small className="p-error">{errors.addressLine1}</small>}
                                </div>
                                <div className="col-12 md:col-4">
                                    <GlobalInputField type="text" name="addressLine2" id="addressLine2" label="Address Line 2" placeholder="Enter Address Line 2" value={formValues.addressLine2} onChange={(e) => handleInputChange("addressLine2", e.target.value)} maxLength={100} />
                                    {errors.addressLine2 && <small className="p-error">{errors.addressLine2}</small>}
                                </div>
                                <div className="col-12 md:col-4">
                                    <GlobalInputField
                                        type="text"
                                        name="zipCode"
                                        id="zipCode"
                                        label="Zip Code"
                                        placeholder="Enter Zip Code"
                                        value={formValues.zipCode}
                                        isRequired={true}
                                        onChange={(e) => {
                                            // Update only if the value contains numbers
                                            const numericValue = e.target.value.replace(/[^0-9]/g, "");

                                            // Correctly update the formValues using setFormValues
                                            setFormValues((prevValues) => ({
                                                ...prevValues, // Preserve the rest of the form values
                                                zipCode: numericValue, // Update only zipCode
                                            }));
                                        }}
                                        maxLength={5}
                                    />
                                    {errors.zipCode && <small className="p-error">{errors.zipCode}</small>}
                                </div>
                                <div className="col-12 md:col-4">
                                    <GlobalInputField type="text" name="state" id="state" label="State" placeholder="Enter State" value={formValues.state} onChange={(e) => handleInputChange("state", e.target.value)} disabled maxLength={50} />
                                    {errors.state && <small className="p-error">{errors.state}</small>}
                                </div>
                                <div className="col-12 md:col-4">
                                    <GlobalInputField type="text" name="city" id="city" label="City" placeholder="Enter City" value={formValues.city} onChange={(e) => handleInputChange("city", e.target.value)} disabled maxLength={50} />
                                    {errors.city && <small className="p-error">{errors.city}</small>}
                                </div>
                                <div className="col-12 md:col-4">
                                    <GlobalDropdown
                                        label="Authentication Type"
                                        name="authenticationType"
                                        id="authenticationType"
                                        options={authenticationTypeOptions}
                                        optionLabel="label"
                                        optionValue="value"
                                        value={formValues?.authenticationType}
                                        onChange={(e) => handleInputChange("authenticationType", e.value)}
                                        placeholder="Select Authentication Type"
                                        isRequired={true}
                                    />
                                    {errors.authenticationType && <small className="p-error">{errors.authenticationType}</small>}
                                </div>
                                <div className="col-12 md:col-4">
                                    <GlobalInputField type="text" name="pocFirstName" id="pocFirstName" label="POC First Name" placeholder="Enter POC First Name" value={formValues.pocFirstName} onChange={(e) => handleInputChange("pocFirstName", e.target.value)} isRequired={true} maxLength={50} />
                                    {errors.pocFirstName && <small className="p-error">{errors.pocFirstName}</small>}
                                </div>
                                <div className="col-12 md:col-4">
                                    <GlobalInputField type="text" name="pocLastName" id="pocLastName" label="POC Last Name" placeholder="Enter POC Last Name" value={formValues.pocLastName} onChange={(e) => handleInputChange("pocLastName", e.target.value)} isRequired={true} maxLength={50} />
                                    {errors.pocLastName && <small className="p-error">{errors.pocLastName}</small>}
                                </div>
                                <div className="col-12 md:col-4">
                                    <GlobalInputField type="text" name="pocEmail" id="pocEmail" label="POC Email" placeholder="Enter POC Email" value={formValues.pocEmail} onChange={(e) => handleInputChange("pocEmail", e.target.value)} isRequired={true} maxLength={50} disabled={editData.invitation_date} />
                                    {errors.pocEmail && <small className="p-error">{errors.pocEmail}</small>}
                                </div>
                                <div className="col-12 md:col-4">
                                    <GlobalInputField
                                        type="text"
                                        name="pocPhoneNo"
                                        id="pocPhoneNo"
                                        label="POC Phone No"
                                        placeholder="Enter POC Phone No"
                                        value={formValues.pocPhoneNo}
                                        isRequired={true}
                                        onChange={(e) => {
                                            // Update only if the value contains numbers
                                            const numericValue = e.target.value.replace(/[^0-9]/g, "");
                                            const formattedValue = numericValue
                                                .replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3") // Format as 123-456-7898
                                                .substr(0, 12); // Limit length
                                            setFormValues((prevValues) => ({
                                                ...prevValues, // Preserve the rest of the form values
                                                pocPhoneNo: formattedValue, // Update only zipCode
                                            }));
                                        }}
                                        maxLength={12}
                                    />
                                    {errors.pocPhoneNo && <small className="p-error">{errors.pocPhoneNo}</small>}
                                </div>
                                <div className="col-12">
                                    <GlobalTextarea type="text" name="description" id="description" label="Description" placeholder="Enter Description" rows={10} value={formValues.description} onChange={(e) => handleInputChange("description", e.target.value)} />
                                </div>
                                <div className="col-12">
                                    <div className="dp-flex justify-content-end ">
                                        <SecondaryButton type="button" name="cancelButton" id="cancelButton" label="Cancel" onClick={onHide} />
                                        <PrimaryButton type="button" name="saveButton" id="saveButton" label="Update" onClick={handleUpdate} loading={updateLoading} />
                                    </div>
                                </div>
                            </div>
                        </>
                    )}
                    {/* Company User Section */}
                    {activeTab === "companyUser" && (
                        <>
                            <div className="grid">
                                <div className="md:col-8"></div>
                                <div className="col-12 md:col-4">
                                    <GlobalInputField name="name" type="text" id="name" placeholder="Search by name, email, username, role" iconField={<BsSearch />} isRequired={false} onChange={(e) => setKeyword(e.target.value)} />
                                </div>
                            </div>
                            {companyUserListLoading ? (
                                <CustomLoading />
                            ) : (
                                <>
                                    <div className="grid custom_scroll">
                                        <div className="col-12">
                                            <DataTable
                                                value={companyUserListData?.data}
                                                responsiveLayout="scroll"
                                                stripedRows
                                                emptyMessage="No record available."
                                                paginator
                                                onSort={onSort}
                                                sortable
                                                sortField={sortField}
                                                sortOrder={sortOrder}
                                                onPage={onCustomPage}
                                                paginatorTemplate={PaginatorTemplate(pagination.isFirstPage, pagination.isLastPage, currentPage, pagination.totalPages, pageSize, page, pagination.totalRecords, pageInputTooltip, setCurrentPage, setPage, setPageSize, setPageInputTooltip)}
                                                rows={pageSize}
                                            >
                                                <Column field="first_name" header="First Name"></Column>
                                                <Column field="last_name" header="Last Name"></Column>
                                                <Column field="email" header="Email"></Column>
                                                <Column field="user_role" header="Role"></Column>
                                                <Column field="created_by" header="Created By"></Column>
                                                <Column field="created_at" header="Created On" body={(rowData) => formateDateTime(rowData.created_at)}></Column>
                                                <Column body={statusTemplate} header="Status"></Column>
                                                <Column body={editTemplate} header="Action"></Column>
                                            </DataTable>
                                        </div>
                                    </div>
                                </>
                            )}
                        </>
                    )}
                </div>
            </div>
            {/* </form> */}

            {/* Dialog  */}

            {isEdit && (
                <GlobalDialogIndex
                    showHeader={true}
                    visible={isEdit}
                    onHide={() => setIsEdit(false)}
                    header={"Edit User"}
                    draggable={true}
                    breakpoints={{ "960px": "80vw", "640px": "90vw" }}
                    style={{ width: "50vw" }}
                    component={<EditUser onHide={() => setIsEdit(false)} editUserData={editUserData} companyId={companyId} hideUpdate={onHide} />}
                />
            )}

            {inviteDialog && (
                <GlobalDialogIndex
                    showHeader={true}
                    visible={inviteDialog}
                    onHide={() => setInviteDialog(false)}
                    header={false}
                    draggable={false}
                    breakpoints={{ "960px": "80vw", "640px": "90vw" }}
                    style={{ width: "20vw" }}
                    component={<InviteDialog onHide={() => setInviteDialog(false)} company={editData} hideUpdate={onHide} />}
                />
            )}
        </>
    );
};

export default EditCompany;
