import appURL from "../../constants/appURL";
// import { axiosApi } from '../../service/axios_api';
import Cookies from "js-cookie";
import axiosInstance from "../../service/axios_apis";

const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");

// Create the logoutActiveUser thunk to notify the server about the logout
export const logoutActiveUser = createAsyncThunk("auth/logout", async (_, { rejectWithValue, fulfillWithValue }) => {
    try {
        // Call your logout API (if needed)
        const { data } = await axiosInstance.post(appURL.baseUrl + appURL.adminLogout, {}, { withCredentials: true });

        Cookies.remove("access_token"); // Replace with your cookie name
        localStorage.removeItem("user");
        localStorage.clear();
        window.location.href = "/";

        return fulfillWithValue(data); // Return the API response, if needed
    } catch (error) {
        return rejectWithValue(error.response && error.response.data.Message ? error.response.data.Message : error.message);
    }
});

const loginUserReducer = createSlice({
    name: "loginUser",
    initialState: {},
    reducers: {
        resetChangeStatus(state, action) {
            return { ...state, success: undefined, editSuccess: undefined, error: undefined, loading: undefined, logoutSuccess: undefined, logoutLoading: undefined };
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(loginUser.pending, (state, action) => {
                return { ...state, loading: true };
            })
            .addCase(loginUser.fulfilled, (state, action) => {
                localStorage.setItem("user", JSON.stringify(action.payload));
                return { ...state, loading: false, success: true, user: action.payload };
            })
            .addCase(loginUser.rejected, (state, action) => {
                return {
                    ...state,
                    loading: false,
                    success: false,
                    error: action.payload,
                };
            });

        builder
            .addCase(fetchUserDetails.pending, (state, action) => {
                return { ...state, loading: true };
            })
            .addCase(fetchUserDetails.fulfilled, (state, action) => {
                localStorage.setItem("user", JSON.stringify(action.payload));
                return { ...state, loading: false, user: action.payload, success: true };
            })
            .addCase(fetchUserDetails.rejected, (state, action) => {
                return {
                    ...state,
                    loading: false,
                    success: false,
                    error: action.payload,
                    user: undefined,
                };
            });

        // Handle the logoutActiveUser async actions
        builder
            .addCase(logoutActiveUser.pending, (state) => {
                state.logoutLoading = true; // Indicate that logout API is in progress
            })
            .addCase(logoutActiveUser.fulfilled, (state) => {
                // Clear cookies and reset the state after successful logout
                
                state.loading = false;
                state.user = undefined;
                localStorage.removeItem("user");
                localStorage.clear();
                Cookies.remove("access_token"); // Replace with your cookie name

                return { logoutLoading: false, logoutSuccess: true };
            })
            .addCase(logoutActiveUser.rejected, (state, action) => {
                // Optionally handle error after logout API fails
                return { logoutLoading: false, error: action.payload };
            });
    },
});

export default loginUserReducer.reducer;
export const { resetChangeStatus, logout } = loginUserReducer.actions;

// Thunks
export const loginUser = createAsyncThunk("auth/user/login", async (body, { rejectWithValue, fulfillWithValue }) => {
    //loginUser/fetch
    try {
        const { data } = await axiosInstance.post(appURL.baseUrl + appURL.adminLogin, body, { withCredentials: true });

        return fulfillWithValue({ ...data.user });
    } catch (error) {
        if (error.response.status === 429) {
            return rejectWithValue("Too many requests. Please try again later.");
        } else if (error.response) {
            // Use the error message returned from the server
            return rejectWithValue(error.response.data.error || error.response.data?.message || "An unknown error occurred");
        } else if (error.request) {
            // No response was received
            return rejectWithValue("No response from the server");
        } else {
            // Something went wrong while making the request
            return rejectWithValue(error.message || "An error occurred");
        }
    }
});

export const fetchUserDetails = createAsyncThunk("auth/me", async (body, { rejectWithValue, fulfillWithValue }) => {
    //loginUser/fetch
    try {
        const { data } = await axiosInstance.post(appURL.baseUrl + appURL.fetchUserDetails, body, { withCredentials: true });
        return fulfillWithValue(data.data);
    } catch (error) {
        Cookies.remove("access_token"); // Replace with your cookie name
        localStorage.removeItem("user");
        localStorage.clear();
        if (error.response) {
            // Use the error message returned from the server
            return rejectWithValue(error.response.data.error || "An unknown error occurred");
        } else if (error.request) {
            // No response was received
            return rejectWithValue("No response from the server");
        } else {
            // Something went wrong while making the request
            return rejectWithValue(error.message || "An error occurred");
        }
    }
});
