import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import GlobalInputField from "../../../ui-components/globalinputfield";
import PrimaryButton from "../../../ui-components/primarybutton/primarybutton";
import SecondaryButton from "../../../ui-components/secondarybutton/secondarybutton";
import { useDispatch, useSelector } from "react-redux";
import { addProductRight, getProductRightList, resetProductRightSlice, updateProductRight, getModuleOrder, getSubModuleOrder } from "../../../redux/auth_slice/product_right_slice";
import { toast } from "react-toastify";
const AddEditRight = (props) => {
    // const dispatch = useDispatch();
    const { onHide, editData } = props;

    const dispatch = useDispatch();
    const productRightAddReducer = useSelector((state) => state.productRight);
    const { addSuccess, addError, addLoading } = productRightAddReducer;

    const productRightUpdateReducer = useSelector((state) => state.productRight);
    const { updateSuccess, updateError, updateLoading } = productRightUpdateReducer;

    const [triggerModuleOrderApi, setTriggerModuleOrderApi] = useState(false);
    const [triggerSubModuleOrderApi, setTriggerSubModuleOrderApi] = useState(false);

    const productModuleOrderReducer = useSelector((state) => state.productRight);
    const { moduleOrderLoading, moduleOrderData } = productModuleOrderReducer;

    const productSubModuleOrderReducer = useSelector((state) => state.productRight);
    const { subModuleOrderLoading, subModuleOrderData } = productSubModuleOrderReducer;

    //Formik Vaidations
    const validationSchema = Yup.object().shape({
        right_name: Yup.mixed().required("Right Name is required"),
        module_name: Yup.mixed().required("Module Name is required"),
        sub_modulename: Yup.mixed().required("Sub Module Name is required"),
        display_name: Yup.mixed().required("Display Name is required"),
        module_order: Yup.mixed().required("Module Order is required"),
        sub_module_order: Yup.mixed().required("Sub Module Order is required"),
    });

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            right_name: editData?.right_name || "",
            module_name: editData?.module_name || "",
            sub_modulename: editData?.sub_modulename || "",
            display_name: editData?.display_name || "",
            module_order: editData?.module_order || "",
            sub_module_order: editData?.sub_module_order || "",
        },
        onSubmit: async (values) => {
            if (editData == null) {
                dispatch(addProductRight(values));
            } else {
                const updatedValues = { ...values, id: editData.id }; // Add the id field
                dispatch(updateProductRight(updatedValues));
            }
        },
    });

    useEffect(() => {
        if (addSuccess !== undefined) {
            if (addSuccess === true) {
                toast.success("Right created successfully!");
                dispatch(getProductRightList());
                onHide();
                formik.resetForm();
            } else {
                toast.error(addError);
            }
        }

        return () => {
            dispatch(resetProductRightSlice());
        };
    }, [addSuccess, addError]);

    useEffect(() => {
        if (updateSuccess !== undefined) {
            if (updateSuccess === true) {
                toast.success("Right updated successfully!");
                dispatch(getProductRightList());
                onHide();
                formik.resetForm();
            } else {
                toast.error(updateError);
            }
        }

        return () => {
            dispatch(resetProductRightSlice());
        };
    }, [updateSuccess, updateError]);

    //Formik Error
    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    useEffect(() => {
        const fetchModuleOrder = async () => {
            if (triggerModuleOrderApi && formik.values.module_name) {
                try {
                    dispatch(getModuleOrder({ module_name: formik.values.module_name }));
                } catch (error) {
                    console.error("Network Error:", error);
                } finally {
                    setTriggerModuleOrderApi(false); // Reset trigger
                }
            }
        };

        fetchModuleOrder();

        if (formik.values.module_name === "") {
            formik.setValues({
                ...formik.values, // Preserve other field values
                module_order: "",
            });
        }
    }, [triggerModuleOrderApi, formik.values.module_name]);

    useEffect(() => {
        const fetchSubModuleOrder = async () => {
            if (triggerSubModuleOrderApi && formik.values.sub_modulename) {
                try {
                    dispatch(getSubModuleOrder({ module_name: formik.values.module_name, sub_modulename: formik.values.sub_modulename }));
                } catch (error) {
                    console.error("Network Error:", error);
                } finally {
                    setTriggerSubModuleOrderApi(false); // Reset trigger
                }
            }
        };

        fetchSubModuleOrder();

        if (formik.values.sub_modulename === "") {
            formik.setValues({
                ...formik.values, // Preserve other field values
                sub_module_order: "",
            });
        }
    }, [triggerSubModuleOrderApi, formik.values.sub_modulename]);

    useEffect(() => {
        console.log('moduleOrderData', moduleOrderData)
        if (moduleOrderData !== undefined) {
            if (formik.values.module_order !== moduleOrderData) {
                formik.setValues({
                    ...formik.values, // Preserve other field values
                    module_order: moduleOrderData,
                    sub_modulename: '',
                    sub_module_order: '',
                });
            }
        }

        return () => {
            dispatch(resetProductRightSlice());
        };
    }, [moduleOrderData]);

    useEffect(() => {
        if (subModuleOrderData !== undefined) {
            formik.setValues({
                ...formik.values, // Preserve other field values
                sub_module_order: subModuleOrderData,
            });
        }

        return () => {
            dispatch(resetProductRightSlice());
        };
    }, [subModuleOrderData]);

    return (
        <div>
            <>
                <form onSubmit={formik.handleSubmit}>
                    <div className="grid">
                        <div className="col-12 md:col-6">
                            <GlobalInputField type="text" name="right_name" id="rightName" label="Right Name" placeholder="Enter Right Name" isRequired={true} value={formik.values.right_name} onChange={formik.handleChange} maxLength={100} />
                            {getFormErrorMessage("right_name")}
                        </div>
                        <div className="col-12 md:col-6">
                            <GlobalInputField type="text" name="display_name" id="displayName" label="Display Name" placeholder="Enter Display Name" isRequired={true} value={formik.values.display_name} onChange={formik.handleChange} maxLength={100} />
                            {getFormErrorMessage("display_name")}
                        </div>
                        <div className="col-12 md:col-6">
                            <GlobalInputField type="text" name="module_name" id="moduleName" label="Module Name" placeholder="Enter Module Name" isRequired={true} value={formik.values.module_name} onChange={formik.handleChange} maxLength={100} onBlur={() => setTriggerModuleOrderApi(true)} />
                            {getFormErrorMessage("module_name")}
                        </div>
                        <div className="col-12 md:col-6">
                            <GlobalInputField
                                type="text"
                                name="module_order"
                                id="moduleOrder"
                                label="Module Order"
                                placeholder=""      //  Enter Module Order
                                isRequired={true}
                                value={formik.values.module_order}
                                onChange={(e) => {
                                    const numericValue = e.target.value.replace(/[^0-9]/g, ""); // Remove non-numeric characters
                                    formik.setFieldValue("module_order", numericValue); // Update formik value
                                }}
                                disabled
                                maxLength={3}
                            />
                            {getFormErrorMessage("module_order")}
                        </div>
                        <div className="col-12 md:col-6">
                            <GlobalInputField type="text" name="sub_modulename" id="subModuleName" label="Sub Module Name" placeholder="Enter Sub Module Name" isRequired={true} value={formik.values.sub_modulename} onChange={formik.handleChange} maxLength={100} onBlur={() => setTriggerSubModuleOrderApi(true)} />
                            {getFormErrorMessage("sub_modulename")}
                        </div>
                        <div className="col-12 md:col-6">
                            <GlobalInputField
                                type="text"
                                name="sub_module_order"
                                id="subModuleOrder"
                                label="Sub Module Order"
                                placeholder=""      //  Enter Sub Module Order
                                isRequired={true}
                                value={formik.values.sub_module_order}
                                onChange={(e) => {
                                    const numericValue = e.target.value.replace(/[^0-9]/g, ""); // Remove non-numeric characters
                                    formik.setFieldValue("sub_module_order", numericValue); // Update formik value
                                }}
                                disabled
                                maxLength={3}
                            />
                            {getFormErrorMessage("sub_module_order")}
                        </div>
                    </div>
                    <div className="grid">
                        <div className="col-12">
                            <div className="dp-flex justify-content-end mt-3 ">
                                <SecondaryButton
                                    type="button"
                                    name="cancelButton"
                                    id="cancelButton"
                                    label="Cancel"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        onHide();
                                    }}
                                />
                                <PrimaryButton type="submit" name="saveButton" id="saveButton" label={editData == null ? "Save" : "Update"} disabled={addLoading || updateLoading || moduleOrderLoading || subModuleOrderLoading} />
                            </div>
                        </div>
                    </div>
                </form>
            </>
        </div>
    );
};

export default AddEditRight;
