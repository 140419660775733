import { useFormik } from "formik";
import React, { useEffect } from "react";
import * as Yup from "yup";
import GlobalDropdown from "../../ui-components/globaldropdown";
import GlobalInputField from "../../ui-components/globalinputfield";
import GlobalTextarea from "../../ui-components/globaltextarea";
import PrimaryButton from "../../ui-components/primarybutton/primarybutton";
import SecondaryButton from "../../ui-components/secondarybutton/secondarybutton";
import "./addCompany.scss";
import { useDispatch, useSelector } from "react-redux";
import { addCompany, getCompanyList, getZipCodesList, resetCompanyUserSlice } from "../../redux/auth_slice/company_user_slice";
import { toast } from "react-toastify";

const AddCompany = (props) => {
    const { onHide, isDialog } = props;

    const addCompanyReducer = useSelector((state) => state.companyUser);
    const { addSuccess, addError, addLoading } = addCompanyReducer;

    const validationSchema = Yup.object().shape({
        name: Yup.string()
            .required("Company Name is required")
            .matches(/[a-zA-Z\s]+/, "Company Name must contain only letters and spaces")
            .max(100, "Company Name cannot exceed 100 characters"),
        phone_number: Yup.string()
            .required("Company Phone No is required")
            .matches(/^([0-9\s\-\+\(\)]*)$/, "Invalid phone number format")
            .min(10, "Company Phone No must be at least 10 digits"),
        address_line_1: Yup.string().required("Address Line 1 is required").max(100, "Address Line 1 cannot exceed 100 characters"),
        address_line_2: Yup.string().nullable().max(100, "Address Line 2 cannot exceed 100 characters"),
        zip_code: Yup.string()
            .nullable()
            .matches(/^\d{5}$/, "Zip Code must be a 5-digit number"),
        state: Yup.string().nullable().max(50, "State cannot exceed 50 characters"),
        city: Yup.string().nullable().max(50, "City cannot exceed 50 characters"),
        description: Yup.string().nullable(),
        authentication_type: Yup.number().required("Authentication Type is required").min(0, "Invalid Authentication Type").max(2, "Invalid Authentication Type"),
        poc_first_name: Yup.string()
            .required("POC First Name is required")
            .matches(/[a-zA-Z\s]+/, "POC First Name must contain only letters and spaces")
            .max(50, "POC First Name cannot exceed 50 characters"),
        poc_last_name: Yup.string()
            .required("POC Last Name is required")
            .matches(/[a-zA-Z\s]+/, "POC Last Name must contain only letters and spaces")
            .max(50, "POC Last Name cannot exceed 50 characters"),
        poc_email: Yup.string().email("Invalid email format").required("POC Email is required"),
        poc_phone_number: Yup.string()
            .required("POC Phone No is required")
            .matches(/^([0-9\s\-\+\(\)]*)$/, "Invalid phone number format")
            .min(10, "POC Phone No must be at least 10 digits"),
    });

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            name: "",
            phone_number: "",
            address_line_1: "",
            address_line_2: "",
            zip_code: "",
            state: "",
            city: "",
            authentication_type: "",
            poc_first_name: "",
            poc_last_name: "",
            poc_email: "",
            poc_phone_number: "",
            description: "",
        },
        onSubmit: async (values) => {
            dispatch(addCompany(values));
        },
    });

    // Reset form on component mount
    useEffect(() => {
        formik.resetForm();
    }, []); // This will reset the form when the component is mounted

    //Formik Error
    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    const authenticationTypeOptions = [
        { label: "None", value: "0" },
        { label: "Authenticator", value: "1" },
        { label: "OTP", value: "2" },
    ];

    const dispatch = useDispatch();
    //Redux Selector
    const zipCodeReducer = useSelector((state) => state.companyUser);
    const { zipCodeData, zipCodeError } = zipCodeReducer;

    useEffect(() => {
        if (formik.values.zip_code.length === 5) {
            dispatch(getZipCodesList({ zip: formik.values.zip_code }));
        } else {
            formik.setValues({
                ...formik.values, // Preserve other field values
                city: "",
                state: "",
            });
        }
    }, [formik.values.zip_code]); // Watch changes to zip_code and dispatch

    useEffect(() => {
        if (zipCodeError && zipCodeError !== undefined) {
            toast.error(zipCodeError);
        }

        return () => {
            dispatch(resetCompanyUserSlice());
        };
    }, [zipCodeError]);

    useEffect(() => {
        if (zipCodeData) {
            formik.setValues({
                ...formik.values, // Preserve other field values
                city: zipCodeData.city,
                state: zipCodeData.full_state,
            });
        }
    }, [zipCodeData]);

    useEffect(() => {
        if (addSuccess !== undefined) {
            if (addSuccess === true) {
                toast.success("Company created successfully!");
                onHide();
                formik.resetForm();
                dispatch(getCompanyList());
            } else {
                toast.error(addError);
            }
        }

        return () => {
            dispatch(resetCompanyUserSlice());
        };
    }, [addSuccess, addError]);

    useEffect(() => {
        // Reset the form values when the modal opens
        if (isDialog) {
            formik.resetForm();
        }
    }, [isDialog]); // This will run when the modal is opened or closed

    return (
        <>
            <form onSubmit={formik.handleSubmit}>
                <div className="grid">
                    <div className="col-12 md:col-6">
                        <GlobalInputField type="text" name="name" id="name" label="Company Name" placeholder="Enter Company Name" isRequired={true} value={formik.values.name} onChange={formik.handleChange} maxLength={100} />
                        {getFormErrorMessage("name")}
                    </div>
                    <div className="col-12 md:col-6">
                        <GlobalInputField
                            type="text"
                            name="phone_number"
                            id="phone_number"
                            label="Company Phone No"
                            placeholder="Enter Company Phone No"
                            isRequired={true}
                            value={formik.values.phone_number}
                            onChange={(e) => {
                                // Update only if the value contains numbers
                                const numericValue = e.target.value.replace(/[^0-9]/g, "");
                                const formattedValue = numericValue
                                    .replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3") // Format as 123-456-7898
                                    .substr(0, 12); // Limit length
                                formik.setFieldValue("phone_number", formattedValue);
                            }}
                            maxLength={12}
                        />
                        {getFormErrorMessage("phone_number")}
                    </div>
                    <div className="col-12">
                        <GlobalInputField type="text" name="address_line_1" id="address_line_1" label="Address Line 1" placeholder="Enter Address Line 1" isRequired={true} value={formik.values.address_line_1} onChange={formik.handleChange} maxLength={100} />
                        {getFormErrorMessage("address_line_1")}
                    </div>
                    <div className="col-12">
                        <GlobalInputField type="text" name="address_line_2" id="address_line_2" label="Address Line 2" placeholder="Enter Address Line 2" value={formik.values.address_line_2} onChange={formik.handleChange} maxLength={100} />
                    </div>
                    <div className="col-12 md:col-6">
                        <GlobalInputField
                            type="text"
                            name="zip_code"
                            id="zip_code"
                            label="Zip Code"
                            placeholder="Enter Zip Code"
                            isRequired={true}
                            value={formik.values.zip_code}
                            onChange={(e) => {
                                // Update only if the value contains numbers
                                const numericValue = e.target.value.replace(/[^0-9]/g, "");
                                formik.setFieldValue("zip_code", numericValue);
                            }}
                            maxLength={5}
                        />
                        {getFormErrorMessage("zip_code")}
                    </div>
                    <div className="col-12 md:col-6">
                        <GlobalInputField type="text" name="state" id="state" label="State" placeholder="" value={formik.values.state} disabled onChange={formik.handleChange} maxLength={50} />
                    </div>
                    <div className="col-12 md:col-6">
                        <GlobalInputField type="text" name="city" id="city" label="City" placeholder="" value={formik.values.city} disabled onChange={formik.handleChange} maxLength={50} />
                    </div>
                    <div className="col-12 md:col-6">
                        <GlobalDropdown
                            label="Authentication Type"
                            name="authentication_type"
                            id="authentication_type"
                            options={authenticationTypeOptions}
                            optionLabel="label"
                            optionValue="value"
                            placeholder="Select"
                            isRequired
                            value={formik.values.authentication_type}
                            onChange={formik.handleChange}
                        />
                        {getFormErrorMessage("authentication_type")}
                    </div>
                    <div className="col-12 md:col-6">
                        <GlobalInputField type="text" name="poc_first_name" id="poc_first_name" label="POC First Name" placeholder="Enter POC First Name" isRequired={true} value={formik.values.poc_first_name} onChange={formik.handleChange} maxLength={50} />
                        {getFormErrorMessage("poc_first_name")}
                    </div>
                    <div className="col-12 md:col-6">
                        <GlobalInputField type="text" name="poc_last_name" id="poc_last_name" label="POC Last Name" placeholder="Enter POC Last Name" isRequired={true} value={formik.values.poc_last_name} onChange={formik.handleChange} maxLength={50} />
                        {getFormErrorMessage("poc_last_name")}
                    </div>
                    <div className="col-12 md:col-6">
                        <GlobalInputField type="text" name="poc_email" id="poc_email" label="POC Email" placeholder="Enter POC Email" isRequired={true} value={formik.values.poc_email} onChange={formik.handleChange} maxLength={50} />
                        {getFormErrorMessage("poc_email")}
                    </div>
                    <div className="col-12 md:col-6">
                        <GlobalInputField
                            type="text"
                            name="poc_phone_number"
                            id="poc_phone_number"
                            label="POC Phone No"
                            placeholder="Enter POC Phone No"
                            isRequired={true}
                            value={formik.values.poc_phone_number}
                            onChange={(e) => {
                                // Update only if the value contains numbers
                                const numericValue = e.target.value.replace(/[^0-9]/g, "");
                                const formattedValue = numericValue
                                    .replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3") // Format as 123-456-7898
                                    .substr(0, 12); // Limit length
                                formik.setFieldValue("poc_phone_number", formattedValue);
                            }}
                            maxLength={12}
                        />
                        {getFormErrorMessage("poc_phone_number")}
                    </div>
                    <div className="col-12 ">
                        <GlobalTextarea type="text" name="description" id="description" label="Description" placeholder="Enter Description" rows={5} value={formik.values.description} onChange={formik.handleChange} />
                    </div>
                    <div className="col-12">
                        <div className="dp-flex justify-content-end">
                            <SecondaryButton type="button" name="cancelButton" id="cancelButton" label="Cancel" onClick={onHide} />
                            <PrimaryButton type="submit" name="saveButton" id="saveButton" label="Save" loading={addLoading} />
                        </div>
                    </div>
                </div>
            </form>
        </>
    );
};

export default AddCompany;
