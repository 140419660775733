import React, { useEffect, useState } from "react";
import SecondaryButton from "../../../ui-components/secondarybutton/secondarybutton";
import PrimaryButton from "../../../ui-components/primarybutton/primarybutton";
import GlobalInputField from "../../../ui-components/globalinputfield";
import { useDispatch, useSelector } from "react-redux";
import { getCompanyUserList, resetCompanyUserSlice, updateCompanyUser, fetchRolesList } from "../../../redux/auth_slice/company_user_slice";
import * as Yup from "yup";
import { toast } from "react-toastify";
import GlobalDropdown from "../../../ui-components/globaldropdown";
import CustomLoading from "../../../ui-components/customSpinner/custom_spinner";

const EditUser = (props) => {
    const { onHide, editUserData, companyId } = props;

    const [errors, setErrors] = useState({});

    const dispatch = useDispatch();
    const updateCompanyUserReducer = useSelector((state) => state.companyUser);
    const { updateCompanyUserSuccess, updateCompanyUserError, updateCompanyUserLoading } = updateCompanyUserReducer;

    const rolesReducer = useSelector((state) => state.companyUser);
    const { rolesLoading, rolesData, rolesError } = rolesReducer;

    // State for managing form values
    const [formValues, setFormValues] = useState({
        firstName: editUserData?.first_name || "",
        lastName: editUserData?.last_name || "",
        userName: editUserData?.username || "",
        email: editUserData?.email || "",
        phoneNumber: editUserData?.phone_number || "",
        gender: editUserData?.gender || "",
        roleId: editUserData?.role_id || "",
        address: editUserData?.address || "",
    });

    // Handler for input changes
    const handleInputChange = (name, value) => {
        setFormValues((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const validationSchema = Yup.object({
        firstName: Yup.string().required("First Name is required").max(50, "Max 50 characters"),
        lastName: Yup.string().required("Last Name is required").max(50, "Max 50 characters"),
        userName: Yup.string().required("Username is required").max(50, "Max 50 characters"),
        email: Yup.string().required("Email is required").email("Invalid email"),
        phoneNumber: Yup.string()
            .required("Phone No is required")
            .test("phone-format", "Phone number must be in the format 123-456-7890", (value) => {
                if (!value) return true; // Don't validate format if the value is empty (as required check will handle it)
                return /^[0-9]{3}-[0-9]{3}-[0-9]{4}$/.test(value); // Format validation
            }),
        gender: Yup.string().required("Gender is required").oneOf(["male", "female"], "Gender must be either male or female"),
        roleId: Yup.string().required("Role is required"),

        address: Yup.string().required("Address is required").max(100, "Max 100 characters"),
    });

    const validateForm = async (formValues) => {
        try {
            await validationSchema.validate(formValues, { abortEarly: false });
            setErrors({});
            return {};
        } catch (err) {
            const errors = {};
            err.inner.forEach((error) => {
                errors[error.path] = error.message;
            });
            setErrors(errors);
            return errors;
        }
    };

    const handleUpdate = async () => {
        const errors = await validateForm(formValues);

        if (Object.keys(errors).length > 0) {
            console.log(errors);
            return;
        }
        const payload = {
            id: editUserData?.id || 0,
            first_name: formValues.firstName,
            last_name: formValues.lastName,
            username: formValues.userName,
            email: formValues.email,
            phone_number: formValues.phoneNumber,
            gender: formValues.gender,
            role_id: formValues.roleId,
            address: formValues.address
        };

        payload.id = editUserData.id;

        dispatch(updateCompanyUser(payload));
    };

    useEffect(() => {
        if (updateCompanyUserSuccess !== undefined) {
            if (updateCompanyUserSuccess === true) {
                toast.success("User updated successfully!");
                dispatch(getCompanyUserList({ company_id: companyId }));
                onHide();
            } else {
                toast.error(updateCompanyUserError);
            }
        }

        return () => {
            dispatch(resetCompanyUserSlice());
        };
    }, [updateCompanyUserSuccess, updateCompanyUserError]);

    useEffect(() => {
        const validate = async () => {
            const validationErrors = await validateForm(formValues);
            setErrors(validationErrors);
        };

        validate();
    }, [formValues]);

    useEffect(() => {
        dispatch(fetchRolesList());
    }, []);

    console.log("roles", rolesData);

    return (
        <>
            {rolesLoading ? (
                <CustomLoading />
            ) : (
                <>
                    <div className="grid">
                        <div className="col-12 md:col-6">
                            <GlobalInputField type="text" name="firstName" id="firstName" label="First Name" placeholder="Enter First Name" value={formValues.firstName} isRequired={true} onChange={(e) => handleInputChange("firstName", e.target.value)} maxLength={50} />
                            {errors.firstName && <small className="p-error">{errors.firstName}</small>}
                        </div>
                        <div className="col-12 md:col-6">
                            <GlobalInputField type="text" name="lastName" id="lastName" label="Last Name" placeholder="Enter Last Name" value={formValues.lastName} isRequired={true} onChange={(e) => handleInputChange("lastName", e.target.value)} maxLength={50} />
                            {errors.lastName && <small className="p-error">{errors.lastName}</small>}
                        </div>
                        <div className="col-12 md:col-6">
                            <GlobalInputField type="text" name="email" id="email" label="Email" placeholder="Enter Email" value={formValues.email} isRequired={true} onChange={(e) => handleInputChange("email", e.target.value)} maxLength={50} disabled />
                            {errors.email && <small className="p-error">{errors.email}</small>}
                        </div>
                        <div className="col-12 md:col-6">
                            <GlobalInputField type="text" name="userName" id="userName" label="Username" placeholder="Enter Username" value={formValues.userName} isRequired={true} onChange={(e) => handleInputChange("userName", e.target.value)} maxLength={50} disabled />
                            {errors.userName && <small className="p-error">{errors.userName}</small>}
                        </div>
                        <div className="col-12 md:col-6">
                            <GlobalInputField
                                type="text"
                                name="phoneNumber"
                                id="phoneNumber"
                                label="Phone No"
                                placeholder="Enter Phone No"
                                isRequired={true}
                                value={formValues.phoneNumber}
                                onChange={(e) => {
                                    // Update only if the value contains numbers
                                    const numericValue = e.target.value.replace(/[^0-9]/g, "");
                                    const formattedValue = numericValue
                                        .replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3") // Format as 123-456-7898
                                        .substr(0, 12); // Limit length
                                    handleInputChange("phoneNumber", formattedValue);
                                }}
                                maxLength={12}
                            />
                            {errors.phoneNumber && <small className="p-error">{errors.phoneNumber}</small>}
                        </div>
                        <div className="col-12 md:col-6">
                            <GlobalDropdown
                                label="Gender"
                                name="gender"
                                id="gender"
                                options={[
                                    { label: "Male", value: "male" },
                                    { label: "Female", value: "female" },
                                ]}
                                optionLabel="label"
                                // optionValue="value"
                                value={formValues.gender.toLowerCase()}
                                onChange={(e) => handleInputChange("gender", e.value)}
                                placeholder="Select Gender"
                                isRequired={true}
                            />
                            {errors.gender && <small className="p-error">{errors.gender}</small>}
                        </div>
                        <div className="col-12 md:col-6">
                            <GlobalDropdown label="Role" name="roleId" id="roleId" options={rolesData} optionLabel="role_name" optionValue="id" value={formValues.roleId} onChange={(e) => handleInputChange("roleId", e.value)} placeholder="Select Role" isRequired={true} />
                            {errors.roleId && <small className="p-error">{errors.roleId}</small>}
                        </div>
                        <div className="col-12 md:col-6">
                            <GlobalInputField type="text" name="address" id="address" label="Address" placeholder="Enter Address" value={formValues.address} isRequired={true} onChange={(e) => handleInputChange("address", e.target.value)} maxLength={50} />
                            {errors.address && <small className="p-error">{errors.address}</small>}
                        </div>
                        
                    </div>
                    <div className="grid">
                        <div className="col-12">
                            <div className="dp-flex justify-content-end mt-3">
                                <SecondaryButton type="button" name="cancelButton" id="cancelButton" label="Cancel" onClick={onHide} />
                                <PrimaryButton type="submit" name="saveButton" id="saveButton" label="Update" onClick={handleUpdate} loading={updateCompanyUserLoading} />
                            </div>
                        </div>
                    </div>
                </>
            )}
        </>
    );
};

export default EditUser;
