import React, { useEffect } from "react";
import { BsPersonAdd, BsTrash } from "react-icons/bs";
import PrimaryButton from "../../ui-components/primarybutton/primarybutton";
import SecondaryButton from "../../ui-components/secondarybutton/secondarybutton";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { getCompanyList, invitePOC, reinvitePOC, resetCompanyUserSlice } from "../../redux/auth_slice/company_user_slice";

const InviteDialog = (props) => {
    const { onHide, company, hideUpdate } = props;

    const dispatch = useDispatch();

    // //Redux Selector
    const invitePOCReducer = useSelector((state) => state.companyUser);
    const { inviteLoading, inviteError, inviteSuccess } = invitePOCReducer;

    console.log(company);
    const handleInvite = () => {
        if (company.poc_user_id === null) {
            dispatch(
                invitePOC({
                    company_id: company.id,
                    email: company.poc_email,
                    first_name: company.poc_first_name,
                    last_name: company.poc_last_name,
                    phone_number: company.poc_phone_number,
                })
            );
        }
        else {
            dispatch(
                reinvitePOC({ sso_id: company.poc_user_id })
            );
        }
        
    };

    useEffect(() => {
        if (inviteSuccess !== undefined) {
            if (inviteSuccess === true) {
                toast.success("POC invited successfully!");
                hideUpdate();
                onHide();
                dispatch(getCompanyList());
            } 
        }
        else {
            toast.error(inviteError);
        }
        return () => {
            dispatch(resetCompanyUserSlice());
        };
    }, [inviteSuccess, inviteError, dispatch]);

    return (
        <>
            <div className="delete_Icon">
                <BsPersonAdd />
            </div>
            <div className="delete_header">
                <h5>Invite POC</h5>
                <p>Are you sure you want to invite the Company POC?</p>
            </div>
            <div className="delete_button">
                <SecondaryButton label="Cancel" type="button" onClick={onHide} style={{ width: "100%" }} disabled={inviteLoading} />
                <PrimaryButton label="Invite" type="button" onClick={handleInvite} style={{ width: "100%" }} disabled={inviteLoading} />
            </div>
        </>
    );
};
export default InviteDialog;
